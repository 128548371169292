import React from "react";
import styled, { keyframes } from "styled-components";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import { bounce } from "react-animations";
import {
  // BrowserRouter as Router,
  HashRouter as Router,
  Route,
  Link,
  useRouteMatch,
  useParams,
  Outlet,
  useMatch,
  useResolvedPath,
} from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Switch from "@mui/material/Switch";
import { store } from "../reducers/store";
import { createTheme } from "@mui/material/styles";
import { connect, useSelector } from "react-redux";

const bounceAnimation = keyframes`${bounce}`;
const params = new URLSearchParams(window.location.search);

const localStorageGlobal = JSON.parse(localStorage.getItem("global"));
if (!localStorageGlobal) {
  //Check if we have global settings, if not, create them
  console.log("DO WE HAVE LOCAL STORAGE???: ", localStorageGlobal);
  localStorage.setItem(
    "global",
    JSON.stringify({
      darkMode: false,
      useCustomTheme: false,
      customTheme: false,
    })
  );
}
const isOverlay =
  new URL(window.location.href).pathname.split("/")[3] === "overlay";
export const darkMode = isOverlay
  ? false
  : localStorageGlobal && localStorageGlobal.useCustomTheme
  ? localStorageGlobal.customTheme
  : params.get("mode") !== "light"
  ? params.get("mode") === "dark" ||
    JSON.parse(localStorage.getItem("global")).darkMode
  : false;
if (darkMode) document.body.classList.add("dark");
console.log("Global.js localStorageGlobal: ", darkMode);

// if (darkMode) document.body.classList.add("dark");

console.log("Globaljs store: ", store.getState().global);

export const themeMode = createTheme({
  palette: {
    mode: darkMode ? "dark" : "light",
  },
});

export const red = "#CC0C0D";
export const redLight = "#e28e8e";
export const blue = "#3333B7";
export const blueLight = "#949CC1";
export const grey = "#808080";
export const lightGrey = "#ebf1f4";
export const lightGrayBorder = "#cccccc90";
export const backgroundColor = darkMode ? "#131A1F" : "#C0D4DE";
export const contentBackgroundColor = darkMode ? "#1F2A34" : "#F7F7F7";
export const modalBackgroundColor = darkMode ? "#131A1F" : "#fff";
export const contentDropShadow = darkMode ? "#14151C" : "#9a9a9a25";
export const backgroundColorDark = "#2B2E39";
export const mainFontColor = darkMode ? "#EBF1F4" : "#2D2B2D";
export const mainFontColorLight = darkMode ? "#EBF1F490" : "#2b2c3390";
export const green = "#0F9C48";
export const uiGreen = "#43922a";
export const uiOrange = "#d07a2d";
export const uiBlue = "#3576a4";
export const uiPurple = "#4b3179";
export const uiPurpleLight = "#895ADB";
export const uiYellow = "#bb9b34";
export const uiBrown = "#4f350f";

// Main Styles
export const ContentWrap = styled.section.attrs({
  className: "ContentWrap",
})`
  max-width: ${(props) => props.maxWidth ?? "none"};
  display: flex;
  flex-direction: column;
  align-self: center;
  margin: 0 auto;
  min-height: 100vh;
  position: relative;
`;
export const FlexColumn = styled.div`
  max-width: ${(props) => props.maxWidth ?? "none"};
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.align ?? "flex-start"};
  justify-content: ${(props) => props.justify ?? "flex-start"};
  flex: 1;
  transition: 0.3s;
  margin: ${(props) => props.margin ?? null};
`;
export const FlexRow = styled.div`
  max-width: ${(props) => props.maxWidth ?? "none"};
  display: flex;
  flex-direction: row;
  align-items: ${(props) => props.align ?? "flex-start"};
  justify-content: ${(props) => props.justify ?? "flex-start"};
  flex: 1;
  transition: 0.3s;
  margin: ${(props) => props.margin ?? null};
`;
export const FlexRowFluid = styled.div`
  max-width: ${(props) => props.maxWidth ?? "none"};
  display: flex;
  flex-direction: ${(props) => props.direction ?? "row"};
  align-items: ${(props) => props.align ?? "flex-start"};
  justify-content: ${(props) => props.justify ?? "flex-start"};
  flex: 1;
  transition: 0.3s;
  margin: ${(props) => props.margin ?? null};
`;
export const ContentSection = styled.section.attrs({
  className: "ContentSection",
})`
  position: relative;
  /* background: ${contentBackgroundColor}; */
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 15px;
  border-radius: 20px;
  transition: 0.3s;
  align-self: stretch;
  /* filter: drop-shadow(0px 6px 13px ${contentDropShadow}); */
  /* animation: 1s ${bounceAnimation}; */
`;
export const LargeButton = styled.button`
  background: ${(props) =>
    props.gradient
      ? "linear-gradient(-203deg, #E08185 0,#9F93CF 74%, #8989D5 100%)"
      : red};
  border: none;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: center;
  top: 0;
  padding: 15px 25px;
  border-radius: 40px;
  font-size: 16px;
  /* width: 100%; */
  filter: drop-shadow(0px 3px 6px #9a9a9a20);
  cursor: pointer;
  transition: 0.3s;
  font-family: "Montserrat", sans-serif;
`;
export const LargeButtonSecondary = styled.button.attrs({
  className: "LargeButtonSecondary",
})`
  border: none;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: center;
  top: 0;
  padding: 15px 25px;
  border-radius: 40px;
  font-size: 16px;
  filter: drop-shadow(0px 3px 6px #9a9a9a20);
  cursor: pointer;
  transition: 0.3s;
  font-family: "Montserrat", sans-serif;
  box-sizing: "border-box";
  box-shadow: inset 0px 0px 0px 2px ${red};
`;
export const QuickToggleButton = styled.button.attrs({
  className: "QuickToggleButton",
})`
  background: ${(props) => props.color && props.color};
  border: ${(props) =>
    props.border || `${darkMode ? "1px" : "2px"} solid ${redLight}`};
  display: flex;
  flex-direction: ${(props) => props.direction ?? "row"};
  position: relative;
  align-items: ${(props) => props.align ?? "center"};
  justify-content: ${(props) => props.justify ?? "center"};
  top: 0;
  padding: 10px;
  border-radius: 40px;
  font-size: 16px;
  /* width: 100%; */
  filter: drop-shadow(0px 3px 6px #9a9a9a20);
  cursor: pointer;
  transition: 0.3s;
  font-family: "Montserrat", sans-serif;
  position: relative;
`;
export const FloatingButton = styled.button`
  background: ${red};
  border: none;
  display: flex;
  flex-direction: row;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: ${red};
  width: 50px;
  height: 50px;
  bottom: 20px;
  right: 20px;
  border-radius: 50px;
  filter: drop-shadow(0px 3px 6px #9a9a9a20);
  cursor: pointer;
  transition: 0.3s;
  z-index: 100;
`;
export const ButtonText = styled.span`
  font-family: "Montserrat", sans-serif;
  color: ${(props) => (props.secondary ? red : "#fff")};
  text-transform: uppercase;
  font-weight: 700;
`;
export const TextOnlyButton = styled.section`
  color: ${red};
  font-weight: 600;
  margin: 20px;
  font-size: ${(props) => props.size ?? "20px"};
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
`;
export const SlideUpModal = styled.section.attrs({
  className: "SlideUpModal",
})`
  position: fixed;
  bottom: -150vh;
  background-color: ${(props) => props.backgroundColor && `${backgroundColor}`};
  /* width: 100%; */
  width: 100vw;
  max-width: ${(props) => props.maxWidth || "100vw"};
  left: 0;
  right: 0;
  padding: ${(props) => props.padding ?? "20px"};
  /* transition: bottom 0.3s ease-out; */
  transition: 0.3s;
  z-index: ${(props) => (props.zIndex ? props.zIndex + 1 : 150)};
  border-radius: 20px 20px 0 0;
  max-height: ${(props) => props.maxHeight ?? "95vh"};
  height: ${(props) => props.height ?? "auto"};
  /* overflow-y: auto; */
  overflow-x: hidden;
  margin: 0 auto;
`;
export const ModalOverlay = styled.section.attrs({
  className: "ModalOverlay",
})`
  position: fixed;
  display: none;
  background-color: ${(props) => props.background || "rgba(0, 0, 0, 0.75)"};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
`;
export const Navbar = styled.ul`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  /* border: 1px solid black; */
  margin: 0;
  padding: 0;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "600px")};
  margin: 0 auto;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
`;
export const NavbarLi = styled.li.attrs({
  className: "NavbarLi",
})`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: ${(props) =>
    props.active ? `2px solid #fff` : `2px solid #fff`};
  background-color: ${(props) => props.active && `${red}`};
  border-right: ${(props) => props.isLast && null};
`;
export const NavbarLink = styled(Link)(({ active }) => ({
  display: "flex",
  padding: "10px",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  textDecoration: "none",
  textTransform: "uppercase",
  color: active && "#fff",
  letterSpacing: 1.5,
  fontSize: ".8em",
  fontWeight: "700",
}));

export const NavbarNoLink = styled.div(({ active }) => ({
  display: "flex",
  cursor: "pointer",
  padding: "10px",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  textDecoration: "none",
  textTransform: "uppercase",
  color: active ? "#fff" : "inherit",
  letterSpacing: 1.5,
  fontSize: ".8em",
  fontWeight: "700",
}));

export const Text = styled.span.attrs({
  className: "Text",
})`
  /* text-align: ${(props) => props.align ?? "left"};  */
  font-size: ${(props) => props.size ?? "16px"};
  color: ${(props) =>
    props.color === "red"
      ? `${red}!important`
      : props.color === "redLight"
      ? `${redLight}!important`
      : props.color === "blue"
      ? `${blue}!important`
      : props.color === "blueLight"
      ? `${blueLight}!important`
      : props.color === "green"
      ? `${green}!important`
      : props.color
      ? `${props.color}!important`
      : "none"};
`;
export const FormInputField = styled(TextField)((props) => ({
  boxShadow: "0px 3px 6px #9a9a9a20",
}));
export const DateRangeField = styled(TextField)(() => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      // borderColor: "red",
      borderRadius: 20,
    },
  },
  boxShadow: "0px 3px 6px #9a9a9a20",
  borderRadius: 10,
}));
export const FormSelectField = styled(Select)((props) => ({
  boxShadow: "0px 3px 6px #9a9a9a20",
  // borderRadius: 50,
  "& fieldset": {
    border: `${props.border}!important`,
    backgroundColor: props.backgroundColor,
  },
}));

export const SwipeTabs = styled(Tabs)((props) => ({
  marginTop: props.marginTop ?? "20px",
  borderBottom: `1px solid ${blueLight}`,
  "& .MuiTabs-indicator": {
    backgroundColor: red,
    width: props.width ? `${props.width}!important` : "auto",
  },
}));

export const SwipeTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  textTransform: "none",
  fontWeight: "600",
  fontSize: "11px!important",
  padding: "5px",
  color: "rgba(255, 255, 255, 0.7)",
  "&.Mui-selected": {
    color: red,
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

export const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid rgba(100, 95, 228, 0.32)`,
  borderRadius: 20,
  // "&:not(:last-child)": {
  //   borderBottom: 0,
  // },
  "&:before": {
    display: "none",
  },
}));

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    display: "flex!important",
    "& .MuiToggleButtonGroup-grouped span": {
      fontFamily: "Montserrat!important",
      fontSize: "12px!important",
      fontWeight: "600",
      color: mainFontColor,
    },
    "& .MuiToggleButtonGroup-grouped.Mui-selected span": {
      fontFamily: "Montserrat!important",
      fontSize: "12px!important",
      fontWeight: "600",
      // color: mainFontColor,
    },
    "& .MuiToggleButtonGroup-grouped": {
      // margin: theme.spacing(0.5),
      // border: 0,
      // "&.Mui-disabled": {
      //   border: 0,
      // },
      flex: 1,
      padding: "5px",
      "&.Mui-selected": { backgroundColor: red },
      "&.Mui-selected:hover": { backgroundColor: red },
      "&.Mui-selected span": { color: "#fff" },
      "&:hover": { backgroundColor: redLight },
      "&:hover span": { color: "#fff" },
      "&:not(:first-of-type)": {
        borderRadius: 15,
      },
      "&:first-of-type": {
        borderRadius: 15,
      },
      "&:last-of-type": {
        flexGrow: 2,
      },
    },
  })
);

export const MaterialUIThemeSwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: darkMode ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: darkMode ? "#003892" : "#001e3c",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: darkMode ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));