import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import profileReducer from "../features/player/playerSlice";
import organizerReducer from "../features/organizer/organizerSlice";
import organizerCreateTournamentReducer from "../features/organizer/organizerCreateTournamentSlice";
import organizerStatsReducer from "../features/organizer/organizerStatsSlice";
import organizerPlayersReducer from "../features/organizer/organizerPlayersSlice";
import globalReducer from "../features/global/globalSlice";
import chartDataReducer from "../features/player/chartDataSlice";
import modalReducer from "../features/modals/modalSlice";
import refreshReducer from "../features/refreshScreen/refreshScreenSlice";
import { playerApi } from "../services/playerApi";
import { organizerApi } from "../services/organizerApi";
import { avatarApi } from "../services/avatarApi";
import { emailApi } from "../services/emailApi";
import { findTournamentApi } from "../services/findTournamentApi";
import { registerApi } from "../services/registerApi";
import { paypalApi } from "../services/paypalApi";
import preRegistrationReducer from "../features/player/preRegistrationSlice";
import { dashboardApi } from "../services/dashboardApi"

export const store = configureStore({
  reducer: {
    global: globalReducer,
    player: profileReducer,
    preRegistration: preRegistrationReducer,
    organizer: organizerReducer,
    organizerPlayers: organizerPlayersReducer,
    organizerStats: organizerStatsReducer,
    tournamentForm: organizerCreateTournamentReducer,
    chartData: chartDataReducer,
    modal: modalReducer,
    refresh: refreshReducer,
    [playerApi.reducerPath]: playerApi.reducer,
    [registerApi.reducerPath]: registerApi.reducer,
    [paypalApi.reducerPath]: paypalApi.reducer,
    [findTournamentApi.reducerPath]: findTournamentApi.reducer,
    [emailApi.reducerPath]: emailApi.reducer,
    [organizerApi.reducerPath]: organizerApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [avatarApi.reducerPath]: avatarApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(playerApi.middleware)
      .concat(registerApi.middleware)
      .concat(paypalApi.middleware)
      .concat(findTournamentApi.middleware)
      .concat(emailApi.middleware)
      .concat(avatarApi.middleware)
      .concat(dashboardApi.middleware)
      .concat(organizerApi.middleware),
})

setupListeners(store.dispatch);
