import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const playerApi = createApi({
  reducerPath: "playerApi",
  baseQuery: fetchBaseQuery({
    //Can probably create an API variables export file to manage and set all base URL's if needed later
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/apiv2/player/`,
  }),
  endpoints: (builder) => ({
    getProfile: builder.query({
      query: (body) => ({
        url: `profile`,
        method: "POST",
        body: body,
      }),
    }),
    getProfileSingle: builder.mutation({
      query: (body) => ({
        url: `profile`,
        method: "POST",
        body: body,
      }),
    }),
    matchHistory: builder.query({
      query: (body) => ({
        url: `v2/match-history`,
        method: "POST",
        body: body,
      }),
    }),
    rankings: builder.query({
      query: (body) => ({
        url: `global-rankings`,
        method: "POST",
        body: body,
      }),
    }),
    getMatchDetails: builder.mutation({
      query: (body) => ({
        url: `match-details`,
        method: "POST",
        body: body,
      }),
    }),
    // getMatchDetails: builder.mutation({
    //   query: (body) => ({
    //     url: `match-details`,
    //     method: "POST",
    //     body: body,
    //   }),
    // }),
    getPracticeHeadToHeadDetails: builder.mutation({
      query: (body) => ({
        url: `practice/get-headtohead-detail`,
        method: "POST",
        body: body,
      }),
    }),
    getProfileSettings: builder.mutation({
      query: (body) => ({
        url: `get-profile-settings`,
        method: "POST",
        body: body,
      }),
    }),
    updateProfileSettings: builder.mutation({
      query: (body) => ({
        url: `update-profile-settings`,
        method: "POST",
        body: body,
        headers: {
          "content-type": "application/json",
        },
      }),
    }),
    requestLogin: builder.mutation({
      query: (body) => ({
        url: `requestlogin`,
        method: "POST",
        body: body,
      }),
    }),
    sendVerificationEmail: builder.mutation({
      query: (body) => ({
        url: `sendverificationemail`,
        method: "POST",
        body: body,
      }),
    }),
    verifyCode: builder.mutation({
      query: (body) => ({
        url: `verifycode`,
        method: "POST",
        body: body,
      }),
    }),
    requestCorrection: builder.mutation({
      query: (body) => ({
        url: `request-correction`,
        method: "POST",
        body: body,
      }),
    }),
    cancelCorrection: builder.mutation({
      query: (body) => ({
        url: `cancel-correction`,
        method: "POST",
        body: body,
      }),
    }),
    submitCorrectionVote: builder.mutation({
      query: (body) => ({
        url: `submit-correction-vote`,
        method: "POST",
        body: body,
      }),
    }),
    correctionDetails: builder.mutation({
      query: (body) => ({
        url: `correction-details`,
        method: "POST",
        body: body,
      }),
    }),
    bindFargoAccount: builder.mutation({
      query: (body) => ({
        url: `fargo/bind-user`,
        method: "POST",
        body: body,
      }),
    }),
    matchHistorySingle: builder.mutation({
      query: (body) => ({
        url: `match-history`,
        method: "POST",
        body: body,
      }),
    }),
    practiceHeadToHead: builder.mutation({
      query: (body) => ({
        url: `practice/get-headtohead`,
        method: "POST",
        body: body,
      }),
    }),
    getPracticeSummary: builder.mutation({
      query: (body) => ({
        url: `practice-summary`,
        method: "POST",
        body: body,
      }),
    }),
    getPracticeDetail: builder.mutation({
      query: (body) => ({
        url: `practice-detail`,
        method: "POST",
        body: body,
      }),
    }),
    deletePracticeDetail: builder.mutation({
      query: (body) => ({
        url: `delete-practice`,
        method: "POST",
        body: body,
      }),
    }),
    playerRank: builder.mutation({
      query: (body) => ({
        url: `charts/player-rank`,
        method: "POST",
        body: body,
      }),
    }),
    playerDuprSingles: builder.mutation({
      query: (body) => ({
        url: `charts/player-dupr-singles`,
        method: "POST",
        body: body,
      }),
    }),
    playerDuprDoubles: builder.mutation({
      query: (body) => ({
        url: `charts/player-dupr-doubles`,
        method: "POST",
        body: body,
      }),
    }),
    playerPPR: builder.mutation({
      query: (body) => ({
        url: `charts/player-ppr`,
        method: "POST",
        body: body,
      }),
    }),
    playerPPRScore: builder.mutation({
      query: (body) => ({
        url: `charts/player-ppr-score`,
        method: "POST",
        body: body,
      }),
    }),
    playerSPR: builder.mutation({
      query: (body) => ({
        url: `charts/player-spr`,
        method: "POST",
        body: body,
      }),
    }),
    pprSummary: builder.mutation({
      query: (body) => ({
        url: `ppr-summary`,
        method: "POST",
        body: body,
      }),
    }),
    removeFromClub: builder.mutation({
      query: (body) => ({
        url: `remove-from-club`,
        method: "POST",
        body: body,
      }),
    }),
    deletePlayerAccount: builder.mutation({
      query: (body) => ({
        url: `profile/delete`,
        method: "POST",
        body: body,
      }),
    }),
    bagTypes: builder.mutation({
      query: (body) => ({
        url: `bags/lookup`,
        method: "POST",
        body: body,
      }),
    }),
    updateMatchHistoryBags: builder.mutation({
      query: (body) => ({
        url: `update-match-history-bags`,
        method: "POST",
        body: body,
      }),
    }),
    updateMatchHistoryTags: builder.mutation({
      query: (body) => ({
        url: `update-match-history-tags`,
        method: "POST",
        body: body,
      }),
    }),
    lastBagsUsed: builder.mutation({
      query: (body) => ({
        url: `last-bags-list`,
        method: "POST",
        body: body,
      }),
    }),
    updateHeadToHeadBags: builder.mutation({
      query: (body) => ({
        url: `practice/update-headtohead-bags`,
        method: "POST",
        body: body,
      }),
    }),
    updateTags: builder.mutation({
      query: (body) => ({
        url: `practice/update-tags`,
        method: "POST",
        body: body,
      }),
    }),
    practiceBagSummary: builder.mutation({
      query: (body) => ({
        url: `practice-bag-summary`,
        method: "POST",
        body: body,
      }),
    }),
    endOfYear: builder.mutation({
      query: (body) => ({
        url: `thatsawrap`,
        method: "POST",
        body: body,
      }),
    }),
    practiceChartsPPR: builder.mutation({
      query: (body) => ({
        url: `practice/charts/ppr`,
        method: "POST",
        body: body,
      }),
    }),
    scoutingReport: builder.mutation({
      query: (body) => ({
        url: `scouting-report`,
        method: "POST",
        body: body,
      }),
    }),
    scoutingReportHeadToHead: builder.mutation({
      query: (body) => ({
        url: `scouting-report-headtohead`,
        method: "POST",
        body: body,
      }),
    }),
    registrationStatus: builder.mutation({
      query: (body) => ({
        url: `game-status`,
        method: "POST",
        body: body,
      }),
    }),
    getOrganizerContactInfo: builder.mutation({
      query: (body) => ({
        url: `orgcontactinfo`,
        method: "POST",
        body: body,
      }),
    }),
    trophyStandingsSummary: builder.mutation({
      query: (body) => ({
        url: `standings/summary`,
        method: "POST",
        body: body,
      }),
    }),
    trophyStandingsDetail: builder.mutation({
      query: (body) => ({
        url: `standings/detail`,
        method: "POST",
        body: body,
      }),
    }),
    registrationVerifyCode: builder.mutation({
      query: (body) => ({
        url: `registration-code`,
        method: "POST",
        body: body,
      }),
    }),
    unlinkDuprAccount: builder.mutation({
      query: (body) => ({
        url: `unbind-dupr`,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const {
  useGetProfileQuery,
  useGetProfileSingleMutation,
  useRequestLoginMutation,
  useSendVerificationEmailMutation,
  useVerifyCodeMutation,
  useMatchHistoryQuery,
  useRankingsQuery,
  useGetMatchDetailsMutation,
  useRequestCorrectionMutation,
  useBindFargoAccountMutation,
  useUpdateProfileSettingsMutation,
  useGetProfileSettingsMutation,
  useMatchHistorySingleMutation,
  usePracticeHeadToHeadMutation,
  useGetPracticeSummaryMutation,
  useGetPracticeDetailMutation,
  useDeletePracticeDetailMutation,
  usePlayerPPRMutation,
  usePlayerPPRScoreMutation,
  usePlayerRankMutation,
  usePlayerSPRMutation,
  useRemoveFromClubMutation,
  usePprSummaryMutation,
  useGetPracticeHeadToHeadDetailsMutation,
  useBagTypesMutation,
  useUpdateMatchHistoryBagsMutation,
  useUpdateHeadToHeadBagsMutation,
  useUpdateTagsMutation,
  usePracticeBagSummaryMutation,
  usePracticeChartsPPRMutation,
  useDeletePlayerAccountMutation,
  useEndOfYearMutation,
  useCorrectionDetailsMutation,
  useSubmitCorrectionVoteMutation,
  useLastBagsUsedMutation,
  useCancelCorrectionMutation,
  useScoutingReportMutation,
  useScoutingReportHeadToHeadMutation,
  useUpdateMatchHistoryTagsMutation,
  useRegistrationStatusMutation,
  useGetOrganizerContactInfoMutation,
  useTrophyStandingsDetailMutation,
  useTrophyStandingsSummaryMutation,
  usePlayerDuprDoublesMutation,
  usePlayerDuprSinglesMutation,
  useRegistrationVerifyCodeMutation,
  useUnlinkDuprAccountMutation,
} = playerApi;
