import React, { useEffect, useState } from "react";
import "./App.scss";
import {
  ContentWrap,
  FlexColumn,
  Navbar,
  NavbarLi,
  FlexRow,
  Text,
  NavbarLink,
  SlideUpModal,
  ModalOverlay,
} from "./styles/Global";
import * as Global from "./styles/Global";
import { connect, useSelector } from "react-redux";
import {
  HashRouter as Router,
  useParams,
  Outlet,
  useMatch,
  useResolvedPath,
} from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import "@ant-design/flowchart/dist/index.css";
import { ToastContainer, toast } from "react-toastify";
import loadable from "@loadable/component";
import ChooseSport from "./pages/organizer/modals/ChooseSport";
import packageJson from "../package.json";
import { setGlobal } from "./features/global/globalSlice";

// import SlideUp from "./components/modals/SlideUp";
const BannerLogo = loadable(() => import("./components/BannerLogo"));

const mapDispatch = {
  setGlobal,
};

function App({ setGlobal }) {
  const global = useSelector((state) => state.global);
  const params = new URLSearchParams(window.location.search);
  const platform = useSelector((state) => state.organizer.platform);
  const organizer = useSelector((state) => state.organizer.organizerData);
  const orgAccountID = useSelector((state) => state.organizer.accountid);
  const organizerId = useSelector((state) => state.organizer.accountid);
  const player = useSelector((state) => state.player);
  const year = new Date().getFullYear();
  const allAccessUsers = [
    "PdejK7ZzhMM5nGJPl6QP57wBXBB2",
    "FD2x0TUi1qTD78xj3vdFcSnzW102",
    "irpWsXBhjQRFhK8pNrtnarqDpeF2",
    "ykQkJvXkI6U5nxuAqDyz82z5Fdt1",
  ];
  const playerNav = [
    { to: "player/profile", name: "Profile" },
    { to: "player/rankings", name: "rankings" },
    { to: "player/practice", name: "practice" },
  ];
  const organizerNav = [
    { to: "organizer", name: "Tournaments" },
    // { to: "organizer/players", name: "Players" },
    { to: "organizer/stats", name: "Stats" },
    { to: "organizer/seasons", name: "Seasons" },
  ];
  const navType = new URL(window.location.href).pathname.split("/")[3];
  const navToUse =
    navType === "organizer"
      ? // ? allAccessUsers.includes(orgAccountID)
        organizerNav
      : // : organizerNav.filter((i) => i.name !== "Seasons")
        playerNav;
  const [viewType, setViewType] = useState(0);
  const { sport } = useParams();
  const localStorageGlobal = JSON.parse(localStorage.getItem("global")) || {};
  //console.log(sport);
  console.log("App localStorageGlobal: ", localStorageGlobal);
  // console.log("App organizer: ", organizer);
  console.log("App player: ", player);
  // console.log("App platform: ", platform);
  console.log("App navType: ", navType);

  useEffect(() => {
  const isOverlay =
    new URL(window.location.href).pathname.split("/")[3] === "overlay";
  const darkMode = isOverlay
    ? false
    : localStorageGlobal && localStorageGlobal.useCustomTheme
    ? localStorageGlobal.customTheme
    : params.get("mode") !== "light"
    ? params.get("mode") === "dark" ||
      JSON.parse(localStorage.getItem("global")).darkMode
    : false;
    console.log("App.js darkMode: ", darkMode);

    setGlobal({
      darkMode: darkMode,
    });

    if (darkMode) document.body.classList.add("dark");
  }, []);

  useEffect(() => {
    if (platform) {
      // console.log("set body class mobile");
      document.body.classList.add("mobile");
    }
  }, [platform]);

  function CustomLink({ children, to, isLast, ...props }) {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (
      <NavbarLi active={match} isLast={isLast}>
        <NavbarLink to={to} {...props} active={match}>
          {children}
        </NavbarLink>
      </NavbarLi>
    );
  }
  return (
    <ContentWrap maxWidth="600px" style={{ paddingTop: 40 }}>
      {navType ? (
        <>
          <nav>
            <Navbar>
              {navToUse.map((item, index) => {
                return (
                  <CustomLink
                    key={index.toString()}
                    to={
                      (navType === "organizer" && !organizer) ||
                      (navType === "player" && player.isUnverified)
                        ? "#"
                        : `${sport}/${item.to}`
                    }
                    isLast={index === navToUse.length - 1}
                  >
                    {item.name}
                  </CustomLink>
                );
              })}
            </Navbar>
          </nav>

          <FlexColumn align="center">
            <BannerLogo viewType={viewType} />
            <Outlet />
            <FlexRow
              align="center"
              justify="center"
              style={{ marginBottom: 10 }}
            >
              <Text size=".7em">
                v{process.env.REACT_APP_VERSION} Build: {packageJson.build} ©
                Scoreholio {year}
              </Text>
            </FlexRow>
          </FlexColumn>
        </>
      ) : (
        <>
          <SlideUpModal maxWidth={"600px"} className="showModalWeb">
            <ChooseSport isHome={true} />
          </SlideUpModal>
          <ModalOverlay
            background={"transparent"}
            className="showModal showOverlay"
          />
        </>
      )}
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </ContentWrap>
  );
}

export default connect(null, mapDispatch)(App);
