import React from "react";
// import ReactDOM from "react-dom/client";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import NoNavLayout from "./Layouts/NoNavLayout";
import BlankLayout from "./Layouts/BlankLayout";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Text } from "./styles/Global";
import { store } from "./reducers/store";
import { Provider } from "react-redux";
import ReactGA from "react-ga4";
import loadable from "@loadable/component";
import UpgradeLayout from "./Layouts/UpgradeLayout";
//import Organizer from "./pages/organizer/Organizer";

const CheckinProgress = loadable(() =>
  import("./pages/checkin/CheckinProgress")
);
const MainProfile = loadable(() => import("./pages/player/MainProfile"));
const OverlayWrapper = loadable(() =>
  import("./pages/streamOverlays/OverlayWrapper")
);
const FindATournament = loadable(() =>
  import("./pages/player/FindATournament")
);
const Rankings = loadable(() => import("./pages/player/Rankings"));
const Practice = loadable(() => import("./pages/player/Practice"));
const Organizer = loadable(() => import("./pages/organizer/Organizer"));
const Players = loadable(() => import("./pages/organizer/Players"));
const Stats = loadable(() => import("./pages/organizer/Stats"));
const Seasons = loadable(() => import("./pages/organizer/Seasons"));
const UpgradeSPRplus = loadable(() =>
  import("./pages/GlobalModals/UpgradeSPRplus")
);
const SPRCorrectionShaming = loadable(() =>
  import("./pages/Global/SPRCorrectionShaming")
);
const ScoutingReport = loadable(() => import("./pages/Global/ScoutingReport"));
const Shop = loadable(() => import("./pages/Global/Shop"));
const WelcomeModal = loadable(() =>
  import("./pages/GlobalModals/WelcomeModal")
);
const AdTracking = loadable(() => import("./pages/GlobalModals/AdTracking"));
const YearEndRecap = loadable(() =>
  import("./pages/GlobalModals/YearEndRecap/YearEndRecap")
);
const DashboardWrapper = loadable(() =>
  import("./pages/dashboard/DashboardWrapper")
);
const windowLocation = window.location.href;
// ReactGA.initialize("G-3GB2H0ZENV");
ReactGA.initialize("G-1LFL9GVTFY");
// ReactGA.pageview(window.location.pathname + window.location.search);

if (process.env.NODE_ENV !== "development") console.log = () => {};

console.log(`${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`);
console.log("process.env.NODE_ENV: ", process.env.NODE_ENV);

const localStorageGlobal = JSON.parse(localStorage.getItem("global"));

if (!localStorageGlobal) {
  //Check if we have global settings, if not, create them
  console.log("DO WE HAVE LOCAL STORAGE???: ", localStorageGlobal);
  localStorage.setItem(
    "global",
    JSON.stringify({
      darkMode: false,
      useCustomTheme: false,
      customTheme: false,
    })
  );
}
// const root = document.getElementById("root");
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename={"/v2"}>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path=":sport/player/profile" element={<MainProfile />} />
          <Route path=":sport/player/rankings" element={<Rankings />} />
          <Route path=":sport/player/practice" element={<Practice />} />
          <Route path=":sport/organizer" element={<Organizer />} />
          <Route path=":sport/organizer/players" element={<Players />} />
          <Route path=":sport/organizer/stats" element={<Stats />} />
          <Route path=":sport/organizer/seasons" element={<Seasons />} />
          <Route
            path="*"
            element={<Text size="2em">Page does not exist!</Text>}
          />
        </Route>
        <Route
          element={
            <NoNavLayout
              hideLogo={windowLocation.includes("create-tournament")}
            />
          }
        >
          <Route
            path=":sport/player/findtournament"
            element={<FindATournament />}
          />
          <Route path=":sport/selfcheckin" element={<CheckinProgress />} />
          <Route path="/selfcheckin" element={<CheckinProgress />} />
          <Route
            path=":sport/spr-correction"
            element={<SPRCorrectionShaming />}
          />
          <Route path=":sport/scouting-report" element={<ScoutingReport />} />
          <Route path=":sport/shop" element={<Shop />} />
        </Route>
        <Route element={<UpgradeLayout />}>
          <Route path=":sport/upgradeSPRplus" element={<UpgradeSPRplus />} />
        </Route>
        <Route element={<UpgradeLayout />}>
          <Route path="modals/welcome" element={<WelcomeModal />} />
        </Route>
        <Route element={<UpgradeLayout />}>
          <Route path="modals/adtracking" element={<AdTracking />} />
        </Route>
        <Route element={<UpgradeLayout />}>
          <Route path="modals/recap" element={<YearEndRecap />} />
        </Route>
        <Route element={<BlankLayout />}>
          <Route path=":sport/overlay" element={<OverlayWrapper />} />
        </Route>
        <Route element={<BlankLayout />}>
          <Route path=":sport/shop" element={<Shop />} />
        </Route>
        <Route element={<BlankLayout />}>
          <Route
            path=":sport/dashboard/wanted-list"
            element={<DashboardWrapper type="wanted" />}
          />
          <Route
            path=":sport/dashboard/gameplay"
            element={<DashboardWrapper type="gameplay" />}
          />
          <Route
            path=":sport/dashboard/setup"
            element={<DashboardWrapper type="setup" />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
