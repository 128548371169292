import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orgModals: { showModal: false, modalType: 10, data: {} },
  playerModals: { showModal: false, modalType: 25, data: {} },
  playerModals2: { showModal: false, modalType: 25, data: {} },
  playerModals3: { showModal: false, modalType: 25, data: {} },
  playerModals3ButtonData: { button: null },
  playerModals4: { showModal: false, modalType: 25, data: {} },
  globalModals: { showModal: false, modalType: 26, data: {} },
  globalModals2: { showModal: false, modalType: 25, data: {} },
  orgSeasonModals: { showModal: false, modalType: 22, data: {} },
  orgTournamentInfo: { showModal: false, modalType: 16, data: {} },
  requestCorrection: false,
  cancelCorrection: { data: {} },
  updatePracticeBags: false,
  updateTags: false,
  updateApp: false,
  organizerEvent: { showModal: false, data: {} },
  organizerSeasonsEvent: { showModal: false, data: {} },
  organizerCreateSeason: { showModal: false, data: {} },
  organizerCreateTournament: { showModal: false, data: {} },
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setModal(state, action) {
      console.log("modalSlice action: ", action.payload);
      const {
        requestCorrection,
        cancelCorrection,
        organizerEvent,
        updatePracticeBags,
        updateTags,
        updateApp,
        organizerCreateTournament,
        orgModals,
        orgSeasonModals,
        orgTournamentInfo,
        organizerSeasonsEvent,
        organizerCreateSeason,
        playerModals,
        playerModals2,
        playerModals3,
        playerModals3ButtonData,
        playerModals4,
        globalModals2,
        globalModals,
      } = action.payload;
      state.orgModals = orgModals || initialState.orgModals;
      state.globalModals =
        globalModals || state.globalModals || initialState.globalModals;
      state.playerModals =
        playerModals || state.playerModals || initialState.playerModals;
      state.playerModals2 =
        playerModals2 || state.playerModals2 || initialState.playerModals2;
      state.playerModals3 =
        playerModals3 || state.playerModals3 || initialState.playerModals3;
      state.playerModals3ButtonData =
        playerModals3ButtonData ||
        state.playerModals3ButtonData ||
        initialState.playerModals3ButtonData;
      state.playerModals4 =
        playerModals4 || state.playerModals4 || initialState.playerModals4;
      state.globalModals2 =
        globalModals2 || state.globalModals2 || initialState.globalModals2;
      state.orgSeasonModals = orgSeasonModals || initialState.orgSeasonModals;
      state.orgTournamentInfo =
        orgTournamentInfo || initialState.orgTournamentInfo;
      state.requestCorrection = requestCorrection;
      state.cancelCorrection = cancelCorrection;
      state.updatePracticeBags = updatePracticeBags;
      state.organizerEvent = organizerEvent || initialState.organizerEvent;
      state.organizerSeasonsEvent =
        organizerSeasonsEvent || initialState.organizerSeasonsEvent;
      state.organizerCreateTournament =
        organizerCreateTournament ||
        state.organizerCreateTournament ||
        initialState.organizerCreateTournament;
      state.organizerCreateSeason =
        organizerCreateSeason ||
        state.organizerCreateSeason ||
        initialState.organizerCreateSeason;
      state.updateApp = updateApp;
      state.updateTags = updateTags;
    },
    clearModals: () => initialState,
  },
});

export const { setModal, clearModals } = modalSlice.actions;

export default modalSlice.reducer;
