import { createSlice } from "@reduxjs/toolkit";
import { SetCustomTheme } from "../../functions/helperFunctions";

const globalStorage = JSON.parse(localStorage.getItem("global")) || {};

const initialState = {
  darkMode:
    globalStorage && globalStorage.useCustomTheme
      ? globalStorage.customTheme
      : false,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setGlobal(state, action) {
      console.log("set globalSlice action: ", action);
      const globalStorage = JSON.parse(localStorage.getItem("global")) || {};
      console.log("set globalSlice globalStorage: ", globalStorage);
      //globalStorage.customTheme is true for dark, false for light
      const { darkMode } = action.payload;

      if (globalStorage.useCustomTheme) {
        SetCustomTheme("none", globalStorage.customTheme ? "dark" : "light");
      } else {
        SetCustomTheme("none", "auto");
      }

      state.darkMode = globalStorage.useCustomTheme
        ? globalStorage.customTheme
        : darkMode || darkMode === null
        ? darkMode
        : false;
      localStorage.setItem(
        "global",
        JSON.stringify({ ...globalStorage, ...state })
      );
    },
    clearGlobal: () => initialState,
  },
});

export const { setGlobal, clearGlobal } = globalSlice.actions;

export default globalSlice.reducer;
