import React, { useEffect, useState } from "react";
import "../App.scss";
import { ContentWrap, FlexColumn } from "../styles/Global";
import { Outlet } from "react-router-dom";
import BannerLogo from "../components/BannerLogo";
import { ToastContainer } from "react-toastify";
import { connect, useSelector } from "react-redux";
import { setGlobal } from "../features/global/globalSlice";

const mapDispatch = {
  setGlobal,
};

const NoNavLayout = ({ hideLogo, setGlobal }) => {
  const [viewType, setViewType] = useState(0);
  const global = useSelector((state) => state.global);
  const params = new URLSearchParams(window.location.search);
  const platform = useSelector((state) => state.organizer.platform);
  const localStorageGlobal = JSON.parse(localStorage.getItem("global")) || {};
  function SetGlobalStatus(param, storage) {
    let theme = "light";
    const mode = params.get(param);
    if (!mode) {
      theme = storage === true ? "dark" : "light";
    } else if (mode === "dark") {
      theme = "dark";
    }
    console.log("SetGlobalStatus theme: ", theme);
    return theme;
  }

  useEffect(() => {
    setGlobal({
      darkMode:
        params.get("mode") !== "light"
          ? params.get("mode") === "dark" ||
            JSON.parse(localStorage.getItem("global")).darkMode
          : false,
    });

    console.log("NoNavLayout localStorageGlobal: ", localStorageGlobal);

    const isOverlay =
      new URL(window.location.href).pathname.split("/")[3] === "overlay";
    const darkMode = isOverlay
      ? false
      : localStorageGlobal &&
        // window.location.hostname === "localhost" &&
        localStorageGlobal.useCustomTheme
      ? localStorageGlobal.customTheme
      : params.get("mode") !== "light"
      ? params.get("mode") === "dark" ||
        JSON.parse(localStorage.getItem("global")).darkMode
      : false;
    console.log("NoNavLayout.js darkMode: ", darkMode);

    if (darkMode) document.body.classList.add("dark");
  }, []);

  useEffect(() => {
    if (platform) {
      // console.log("set body class mobile");
      document.body.classList.add("mobile");
    }
  }, [platform]);
  return (
    <ContentWrap maxWidth="600px">
      <FlexColumn align="center">
        {hideLogo ? null : <BannerLogo viewType={viewType} />}
        <Outlet />
      </FlexColumn>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </ContentWrap>
  );
};

export default connect(null, mapDispatch)(NoNavLayout);
